const priceRange = [
  { price: 10, count: 10 },
  { price: 20, count: 15 },
  { price: 30, count: 20 },
  { price: 40, count: 30 },
  { price: 50, count: 40 },
  { price: 60, count: 50 },
  { price: 70, count: 60 },
  { price: 80, count: 70 },
  { price: 90, count: 80 },
  { price: 100, count: 90 },
  { price: 110, count: 80 },
  { price: 120, count: 70 },
  { price: 130, count: 60 },
  { price: 140, count: 50 },
  { price: 150, count: 40 },
  { price: 160, count: 30 },
  { price: 170, count: 20 },
  { price: 180, count: 10 },
  { price: 10, count: 10 },
  { price: 20, count: 15 },
  { price: 30, count: 20 },
  { price: 40, count: 30 },
  { price: 50, count: 40 },
  { price: 60, count: 50 },
  { price: 70, count: 60 },
  { price: 80, count: 70 },
  { price: 90, count: 80 },
  { price: 100, count: 90 },
  { price: 110, count: 80 },
  { price: 120, count: 70 },
  { price: 130, count: 60 },
  { price: 140, count: 50 },
  { price: 150, count: 40 },
  { price: 160, count: 30 },
  { price: 170, count: 20 },
  { price: 180, count: 10 },
];

const dataFilter = (arr) => {
  const staticArray = [];
  arr.forEach((it) => {
    staticArray.push(it.price);
  });
  const avgValue = staticArray.reduce((a, b) => a + b) / arr.length;
  const minValue = Math.min(...staticArray);
  const maxValue = Math.max(...staticArray);
  return {
    avgValue: avgValue,
    minValue: minValue,
    maxValue: maxValue,
  };
};

export const dataStats = {
  minValue: dataFilter(priceRange).minValue,
  maxValue: dataFilter(priceRange).maxValue,
  avgValue: dataFilter(priceRange).avgValue,
};

export default priceRange;
