import React, { useMemo, useState } from "react";

const ToggleButton = () => {
  const [isChecked, setIsChecked] = useState(false);
  const [isFocused, setIsFocused] = useState(false);

  const switchStyles = useMemo(
    () => ({
      position: "relative",
      display: "inline-block",
      width: "60px",
      height: "34px",
      marginBottom: 0,
    }),
    []
  );

  const inputStyles = useMemo(
    () => ({
      opacity: 0,
      width: 0,
      height: 0,
    }),
    []
  );

  const sliderStyles = useMemo(
    () => ({
      position: "absolute",
      cursor: "pointer",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      width: "50px",
      height: "30px",
      backgroundColor: isChecked ? "black" : "#ccc",
      transition: ".4s",
      borderRadius: "34px",
      border: "none",
      boxShadow: isFocused ? "0 0 1px #2196F3" : "none",
    }),
    [isChecked, isFocused]
  );

  const beforeStyles = useMemo(
    () => ({
      position: "absolute",
      top: "2px",
      height: "26px",
      width: "26px",
      left: isChecked ? "20px" : "4px",
      bottom: "4px",
      backgroundColor: "white",
      transition: ".4s",
      borderRadius: "50%",
    }),
    [isChecked]
  );

  const handleChange = () => {
    setIsChecked(!isChecked);
  };

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  return (
    <label className="switch" style={switchStyles}>
      <input
        type="checkbox"
        checked={isChecked}
        onChange={handleChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
        style={inputStyles}
      />
      <span className="slider round" style={sliderStyles}>
        <span style={beforeStyles}></span>
      </span>
    </label>
  );
};

export default ToggleButton;
