import React from "react";
import Navbar from "../navbar/navbar";
// import { Facilities } from "../../components/facilities/facilities";
import "./header.css";

const Header = () => {
  return (
    <div className="header-main-container">
      <Navbar />
      {/* <Facilities /> */}
    </div>
  );
};

export default Header;
