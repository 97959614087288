import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";
// import Layout from "../../components/layout/layout";
import ViewAllProperties from "../managers/viewAllProperties/viewAllProperties";
import SearchBar from "../../components/searchBar/searchBar";
import { Facilities } from "../../components/facilities/facilities";

function Home() {
  let navigate = useNavigate();
  const [userData, setUserData] = useState(null);

  const { authData } = useAuth();

  const [showLogin, setShowLogin] = useState(true);

  const handleLoginClick = (prev) => {
    setShowLogin((prev) => !prev);
  };

  useEffect(() => {
    if (authData) {
      setUserData(authData);
      console.log(userData);
    } else {
      const storedUserData = localStorage.getItem("userData");

      if (storedUserData) {
        setUserData(JSON.parse(storedUserData));
      }
    }
  }, [authData]);

  function handleLogin() {
    navigate("/login", { replace: true });
  }
  function addProperty() {
    navigate("/addProperty", { replace: true });
  }

  function addResident() {
    navigate("/addResident", { replace: true });
  }
  function viewAllProperties() {
    navigate("/viewAllProperties", { replace: true });
  }

  return (
    <>
      <SearchBar />
      <Facilities />
      <div>
        <h1>
          {/* {JSON.stringify(authData)} */}
          Hi{" "}
          {userData && userData.data && userData.data.name
            ? userData.data.name
            : "User"}
        </h1>
        <ViewAllProperties />
        <button onClick={handleLoginClick}>Login/Signup</button>
        <button onClick={addProperty}>Add Property</button>
        <button onClick={addResident}>Add Resident(s)</button>
        <button onClick={viewAllProperties}>All Properties</button>
      </div>
    </>
  );
}

export default Home;
