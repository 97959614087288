// src/pages/notFound/NotFound.js
import React from "react";
import { Link } from "react-router-dom";
import styles from "./404.module.css"; // Optional: add styles for the NotFound page

const PageNotFound = () => {
  return (
    <div className={styles.notFound}>
      <h1>404 - Not Found</h1>
      <p>Sorry, the page you are looking for does not exist.</p>
      <Link className={styles.notFoundLink} to="/">
        Go to Home Page
      </Link>
    </div>
  );
};

export default PageNotFound;
