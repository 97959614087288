import React from "react";
import "./amenities.css";
const Amenities = () => {
  return (
    <div className="amenities-container">
      <h1>Amenities</h1>
      <h2>Essentials</h2>
      <div className="amenities-checkbox-container">
        <div className="checkbox-column">
          <label>
            <input type="checkbox" /> Wifi
          </label>
          <label>
            <input type="checkbox" /> Washer
          </label>
          <label>
            <input type="checkbox" /> Air conditioning
          </label>
        </div>
        <div className="checkbox-column">
          <label>
            <input type="checkbox" /> Kitchen
          </label>
          <label>
            <input type="checkbox" /> Dryer
          </label>
          <label>
            <input type="checkbox" /> Heating
          </label>
        </div>
      </div>
      <a className="option-link">Show more</a>
    </div>
  );
};

export default Amenities;
