import React, { useState } from "react";
import { produce } from "immer";

import "./addListings.css";
import { useNavigate } from "react-router-dom";
import { ProgressBar } from "../../../components/progressBar/progressBar";
import { RLogoBlack } from "../../../components/svgs/rentpal";
import propertyFormData from "../../../staticData/addListingsData";

const FakeNav = ({ navigate, currentStep }) => {
  return (
    <div className="fake-nav">
      <div className="logo-container">
        <RLogoBlack />
      </div>
      <button
        className="exit-button text-secondary"
        onClick={() => {
          navigate(
            currentStep === 0 ? "/dashboard/listings" : "/save-and-exit"
          );
        }}
      >
        {currentStep === 0 ? "Exit" : "Save and Exit"}
      </button>
    </div>
  );
};

const NavigationButtons = ({ currentStep, onPrevious, onNext }) => {
  return (
    <div className="navigation-buttons">
      {currentStep > 0 && (
        <button className="previous-button" onClick={onPrevious}>
          Back
        </button>
      )}
      <button className="get-started-button text-white" onClick={onNext}>
        {currentStep === 0 ? "Get Started" : "Next"}
      </button>
    </div>
  );
};

const AddListing = () => {
  const [currentStep, setCurrentStep] = useState(0);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedSubcategory, setSelectedSubcategory] = useState("");
  const [formData, setFormData] = useState({
    "Basic Information": {},
    Amenities: {},
    Utilities: {},
    "Additional Features": {},
  });
  const [currentSectionIndex, setCurrentSectionIndex] = useState(0);
  const [currentFieldIndex, setCurrentFieldIndex] = useState(0);
  const navigate = useNavigate();

  const handleCategorySelect = (category) => {
    setSelectedCategory(category);
    setCurrentStep(1);
  };

  const handleSubcategorySelect = (subcategory) => {
    setSelectedSubcategory(subcategory);
    setCurrentStep(2);
  };

  const handleInputChange = (sectionTitle, fieldLabel, value) => {
    setFormData((prevFormData) =>
      produce(prevFormData, (draft) => {
        if (!draft[sectionTitle]) {
          draft[sectionTitle] = {};
        }
        draft[sectionTitle][fieldLabel] = value;
      })
    );
  };

  const handleCheckboxChange = (sectionTitle, fieldName) => {
    setFormData((prevData) => ({
      ...prevData,
      [sectionTitle]: {
        ...prevData[sectionTitle],
        [fieldName]: !prevData[sectionTitle]?.[fieldName],
      },
    }));
  };

  const handleNext = () => {
    const selectedData = propertyFormData.find(
      (data) =>
        data.category === selectedCategory &&
        data.subcategory === selectedSubcategory
    );

    if (!selectedData) return;

    const section = selectedData.sections[currentSectionIndex];

    if (section.title === "Basic Information") {
      const isValid = section.fields.every((field) => {
        const value = formData["Basic Information"]?.[field.label];
        return field.type !== "checkbox" ? !!value : true;
      });

      if (!isValid) {
        alert(
          "Please fill in all required fields in the Basic Information section."
        );
        return;
      }
    }

    if (currentStep === 2) {
      if (currentSectionIndex < selectedData.sections.length - 1) {
        setCurrentSectionIndex((prevIndex) => prevIndex + 1);
        setCurrentFieldIndex(0);
      } else {
        console.log("Form submission logic here...");
      }
    } else {
      setCurrentStep((prevStep) => prevStep + 1);
      setCurrentSectionIndex(0);
      setCurrentFieldIndex(0);
    }
  };

  const handlePrevious = () => {
    if (currentStep === 2) {
      if (currentFieldIndex > 0) {
        setCurrentFieldIndex((prevIndex) => prevIndex - 1);
      } else if (currentSectionIndex > 0) {
        setCurrentSectionIndex((prevIndex) => prevIndex - 1);
        const previousSection = propertyFormData.find(
          (data) =>
            data.category === selectedCategory &&
            data.subcategory === selectedSubcategory
        )?.sections[currentSectionIndex - 1];

        if (previousSection) {
          setCurrentFieldIndex(previousSection.fields.length - 1);
        }
      } else {
        setCurrentStep((prevStep) => prevStep - 1);
      }
    } else {
      setCurrentStep((prevStep) => prevStep - 1);
    }
  };

  const renderCategoryStep = () => (
    <div className="step-container">
      <h2>Select Property Category</h2>
      {Array.from(new Set(propertyFormData.map((data) => data.category))).map(
        (category) => (
          <button key={category} onClick={() => handleCategorySelect(category)}>
            {category}
          </button>
        )
      )}
    </div>
  );

  const renderSubcategoryStep = () => {
    const subcategories = propertyFormData
      .filter((data) => data.category === selectedCategory)
      .map((data) => data.subcategory);

    return (
      <div className="step-container">
        <h2>Select Property Subcategory</h2>
        {subcategories.map((subcategory) => (
          <button
            key={subcategory}
            onClick={() => handleSubcategorySelect(subcategory)}
          >
            {subcategory}
          </button>
        ))}
      </div>
    );
  };

  const renderFormStep = () => {
    const selectedData = propertyFormData.find(
      (data) =>
        data.category === selectedCategory &&
        data.subcategory === selectedSubcategory
    );

    const section = selectedData.sections[currentSectionIndex];
    //YHA PR CHANGES HONY HAIN
    if (section.title === "Basic Information") {
      const field = section.fields[currentFieldIndex];
      return (
        <div key={section.title}>
          <h3>{section.title}</h3>
          <div className="form-field">
            <label>{field.label}</label>
            {field.type === "select" ? (
              <select
                value={formData[section.title]?.[field.label] || ""}
                onChange={(e) =>
                  handleInputChange(section.title, field.label, e.target.value)
                }
              >
                {field.options.map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>
            ) : (
              <input
                type={field.type}
                value={formData[section.title]?.[field.label] || ""}
                onChange={(e) =>
                  handleInputChange(section.title, field.label, e.target.value)
                }
              />
            )}
          </div>
          {/* Render Units field alongside Area and Rent fields */}
          {(field.label === "Area" || field.label === "Rent/Sale Price") && (
            <div className="form-field">
              <label>Units</label>
              <select
                value={formData[section.title]?.["Unit"] || ""}
                onChange={(e) =>
                  handleInputChange(section.title, "Unit", e.target.value)
                }
              >
                {["Marla", "Kanal", "Sq. meters"].map((unit) => (
                  <option key={unit} value={unit}>
                    {unit}
                  </option>
                ))}
              </select>
            </div>
          )}
        </div>
      );
    }

    return (
      <div key={section.title}>
        <h3>{section.title}</h3>
        {section.fields.map((field) => (
          <div key={field.label} className="form-field">
            <label>
              <input
                type={field.type}
                checked={!!formData[section.title]?.[field.label]}
                onChange={() =>
                  handleCheckboxChange(section.title, field.label)
                }
              />
              {field.label}
            </label>
          </div>
        ))}
      </div>
    );
  };

  const renderCurrentStep = () => {
    switch (currentStep) {
      case 0:
        return renderCategoryStep();
      case 1:
        return renderSubcategoryStep();
      case 2:
        return renderFormStep();
      default:
        return <div>Unknown step</div>;
    }
  };

  const progressPercentage = (currentStep / 3) * 100;

  return (
    <div className="add-listings-main">
      <FakeNav currentStep={currentStep} navigate={navigate} />
      <div className="content">{renderCurrentStep()}</div>
      <ProgressBar
        progressPercentage={progressPercentage}
        backgroundColor={"black"}
      />
      <NavigationButtons
        currentStep={currentStep}
        onPrevious={handlePrevious}
        onNext={handleNext}
      />
    </div>
  );
};

export default AddListing;

// const AddListings = () => {
//   const [currentStep, setCurrentStep] = useState(0);

//   const steps = [
//     { component: Step0, progress: 10 },
//     { component: Step1, progress: 20 },
//     { component: Step2, progress: 35 },
//     { component: Step3, progress: 100 },
//   ];

//   const handleNext = () => {
//     if (currentStep < steps.length - 1) {
//       setCurrentStep(currentStep + 1);
//     }
//   };

//   const handlePrevious = () => {
//     if (currentStep > 0) {
//       setCurrentStep(currentStep - 1);
//     }
//   };

//   const StepComponent = steps[currentStep].component;

//   return (
//     <div className="add-listings-main">
//       <StepComponent
//         progressPercentage={steps[currentStep].progress}
//         onNext={handleNext}
//         onPrevious={handlePrevious}
//         currentStep={currentStep}
//       />
//     </div>
//   );
// };

// export default AddListings;
