import React from "react";
import CityData from "../../staticData/cityData";
import "./searchBar.css";
const SearchBar = () => {
  return (
    <div className="searchBar-main-cotainer">
      <form action="/search" method="GET" className="form-cotainer">
        <select className="select-city-field">
          {CityData.map((city, index) => (
            <option className="cities-names" key={index} value={city.name}>
              {city.name}
            </option>
          ))}
        </select>
        <div className="breakline"></div>
        <div className="field-icon-container">
          <input
            type="text"
            id="searchQuery"
            name="q"
            placeholder="Loaction"
            className="location-field"
          />

          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="55"
            height="55"
            viewBox="0 0 55 55"
            fill="none"
            className="search-icon"
          >
            <circle cx="27.3824" cy="27.3824" r="27.3824" fill="#004ACC" />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M37.1787 37.3645C37.8953 37.3645 38.4024 36.8186 38.4024 36.1197C38.4024 35.7921 38.2921 35.4754 38.0496 35.2461L32.8573 30.0921C33.9487 28.6726 34.5991 26.9145 34.5991 25.0036C34.5991 20.3846 30.7848 16.6064 26.1217 16.6064C21.4696 16.6064 17.6443 20.3737 17.6443 25.0036C17.6443 29.6226 21.4586 33.4007 26.1217 33.4007C27.9627 33.4007 29.6604 32.8111 31.0494 31.8283L36.2747 37.0042C36.5173 37.2444 36.837 37.3645 37.1787 37.3645ZM26.1216 31.5881C22.4837 31.5881 19.4741 28.6071 19.4741 25.0036C19.4741 21.4002 22.4837 18.4191 26.1216 18.4191C29.7595 18.4191 32.769 21.4002 32.769 25.0036C32.769 28.6071 29.7595 31.5881 26.1216 31.5881Z"
              fill="white"
            />
          </svg>
        </div>
      </form>
    </div>
  );
};

export default SearchBar;
