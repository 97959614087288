import React from "react";
import "./loginDialog.css";

const LoginDialog = ({ dialogRef }) => {
  return (
    <div ref={dialogRef} className="dialog-main-container">
      <div className="dialog-options">
        <h3 className="login-text">Log in</h3>
        <h3 className="dialog-menu">Sign up</h3>
        <div className="options-break"></div>
        <h3 className="dialog-menu">Rent your home</h3>
        <h3 className="dialog-menu">Help Center</h3>
      </div>
    </div>
  );
};

export default LoginDialog;
