import React from "react";
import "./topTier.css";

const TopTier = () => {
  return (
    <div className="topTier-container">
      <h1>Top-tier stays</h1>
      <div className="topTier-content-container">
        <div className="topTies-icon-content-container">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 14.5l-2-2 1.41-1.41L10 13.67l4.59-4.59L16 10l-6 6z"
              fill="currentColor"
            />
          </svg>
          <h2>Guest Favourites</h2>
          <p>
            The most loved homes on Airbnb,<br></br> according to guests
          </p>
        </div>
      </div>
    </div>
  );
};

export default TopTier;
