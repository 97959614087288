import React, { useState } from "react";
import styles from "./meterReadings.module.css";

const AdminPanel = ({ meters, handleButtonClick }) => {
  const [selectedMeter, setSelectedMeter] = useState(null);
  const [allocatedUnits, setAllocatedUnits] = useState("");
  const [purchasedUnits, setPurchasedUnits] = useState("");
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const openDialog = (meter) => {
    setSelectedMeter(meter); // This should correctly set the selected meter
    setAllocatedUnits("");
    setPurchasedUnits("");
    setIsDialogOpen(true);
    console.log("=====SELECTED METER", meter);
  };

  const closeDialog = () => {
    setIsDialogOpen(false);
  };

  const handleConfirm = () => {
    if (allocatedUnits !== "" || purchasedUnits !== "") {
      handleButtonClick("updateUnits", selectedMeter.number, {
        allocatedUnits: allocatedUnits !== "" ? allocatedUnits : null,
        purchasedUnits: purchasedUnits !== "" ? purchasedUnits : null,
      });
    }
    setIsDialogOpen(false);
  };

  const handleRelayControl = (command) => {
    handleButtonClick(command, selectedMeter.number);
  };

  if (meters.length === 0) return <p>No meter data available</p>;

  return (
    <div className={styles.adminPanel}>
      <h2>Admin Panel</h2>
      <table className={styles.meterTable}>
        <thead>
          <tr>
            <th>Meter Number</th>
            <th>Wattage</th>
            <th>Total Units</th>
            <th>Allocated Units</th>
            <th>Purchased Units</th>
            <th>Relay State</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {meters.map((meter) => (
            <tr key={meter.number}>
              <td>{meter.number}</td>
              <td>
                {meter.currentPowerKilowatts &&
                meter.currentPowerKilowatts !== "-"
                  ? parseFloat(meter.currentPowerKilowatts).toFixed(2)
                  : "N/A"}
              </td>
              <td>
                {meter.totalConsumedEnergy && meter.totalConsumedEnergy !== "-"
                  ? parseFloat(meter.totalConsumedEnergy).toFixed(2)
                  : "N/A"}
              </td>
              <td>
                {meter.allocatedUnits !== "-"
                  ? parseFloat(meter.allocatedUnits).toFixed(2)
                  : "N/A"}
              </td>
              <td>
                {meter.purchasedUnits !== "-"
                  ? parseFloat(meter.purchasedUnits).toFixed(2)
                  : "N/A"}
              </td>
              <td>
                {meter.relayState !== "-"
                  ? meter.relayState
                    ? "ON"
                    : "OFF"
                  : "N/A"}
                <button
                  className={styles.relayButton}
                  onClick={() => handleButtonClick("ON", meter.number)}
                >
                  Turn ON
                </button>
                <button
                  className={styles.relayButton}
                  onClick={() => handleButtonClick("OFF", meter.number)}
                >
                  Turn OFF
                </button>
              </td>

              <td>
                <button
                  className={styles.actionsButton}
                  onClick={() => openDialog(meter)}
                >
                  More Actions
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {isDialogOpen && (
        <div className={styles.dialogOverlay}>
          <div className={styles.dialog}>
            <h3>Actions for {selectedMeter.number}</h3>
            <label>
              Allocated Units:
              <input
                type="number"
                min="0"
                step="any"
                value={allocatedUnits}
                onChange={(e) =>
                  setAllocatedUnits(e.target.value.replace(/[^0-9.]/g, ""))
                }
              />
            </label>
            <label>
              Purchased Units:
              <input
                type="number"
                min="0"
                step="any"
                value={purchasedUnits}
                onChange={(e) =>
                  setPurchasedUnits(e.target.value.replace(/[^0-9.]/g, ""))
                }
              />
            </label>
            <div className={styles.dialogActions}>
              <button className={styles.cancelButton} onClick={closeDialog}>
                Cancel
              </button>
              <button className={styles.confirmButton} onClick={handleConfirm}>
                Confirm
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AdminPanel;
