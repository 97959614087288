import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../context/AuthContext";
import "./listings.css";

const Listings = () => {
  const { authData } = useAuth();
  const navigate = useNavigate();
  return (
    <>
      <div>
        <button
          onClick={() => {
            navigate(
              `/dashboard/listings/${authData.data.uniqueId}/add-listings`
            );
          }}
        >
          add Listings
        </button>
      </div>
      <div>My listings</div>
    </>
  );
};

export default Listings;
