import React, { useState, useEffect, useMemo } from "react";
import "./propertyTypeSelection.css";
import { Property } from "./property";
import { propertyTypes } from "./propertyTypes";
import { LeftArrow, RightArrow } from "../../svgs/rentpal";
import { BoxSelect } from "../../dialogues/dialogues";

const PropertyTypeSelection = () => {
  return (
    <>
      <div className="property-classification">
        <h1>How would you like to classify your property?</h1>
        <div className="proeprty-carousal-container">
          <div className="property-swiper-arrow-left">
            <LeftArrow />
          </div>
          <div className="property-slider-container">
            <div className="property-item-container">
              {propertyTypes.map((property, index) => (
                <BoxSelect key={index} className="property-item">
                  <Property icon={property.icon} label={property.label} />
                </BoxSelect>
              ))}
            </div>
          </div>
          <div className="property-swiper-arrow-right">
            <RightArrow />
          </div>
        </div>
      </div>
    </>
  );
};

export default PropertyTypeSelection;
