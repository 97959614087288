import PropertyTypeSelection from "../../components/admin/propertyTypeSelection/propertyTypeSelection";

const Testing = () => {
  return (
    <>
      <PropertyTypeSelection />
    </>
  );
};

export default Testing;
