const validationPatterns = {
  name: /^[a-zA-Z\s]+$/, // Write your name as per National ID
  cnic: /^\d{5,18}$/, // Between 5-18 digits
  phone: /^\d{10,18}$/, // Between 10 and 15 digits
  email: /^[^\s@]+@[^\s@]+\.[^\s@]+$/, // Basic email pattern
  whatsapp: /^\d{10,15}$/, // Between 10 and 15 digits
  guarantor: /^[a-zA-Z\s]+$/, // Only letters and spaces
  guarantorPhone: /^\d{10,15}$/, // Between 10 and 15 digits
  guarantorCnic: /^\d{5,18}$/, // Enter correct Guarantor ID number
  address: {
    street: /^[a-zA-Z0-9\s,.'-]{3,}$/, // Letters, numbers, and common address characters
    city: /^[a-zA-Z\s]+$/, // Only letters and spaces
    country: /^[a-zA-Z\s]+$/, // Only letters and spaces
  },
  password: /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*?&#^])[A-Za-z\d@$!%*?&#^]{8,}$/, //  password must contain 8 letters/digits, atleast 1 digit and atleast 1 specialcase lettter
};

const validateFormData = (formData) => {
  const validationResults = {
    name: validationPatterns.name.test(formData.name)
      ? null
      : "Invalid name. Only letters and spaces are allowed.",
    cnic: validationPatterns.cnic.test(formData.cnic)
      ? null
      : "Invalid CNIC. Exactly 13 digits are required.",
    phone: validationPatterns.phone.test(formData.phone)
      ? null
      : "Invalid phone number. Between 10 and 15 digits are required.",
    email: validationPatterns.email.test(formData.email)
      ? null
      : "Invalid email address.",
    whatsapp: validationPatterns.whatsapp.test(formData.whatsapp)
      ? null
      : "Invalid WhatsApp number. Between 10 and 15 digits are required.",
    guarantor: validationPatterns.guarantor.test(formData.guarantor)
      ? null
      : "Invalid guarantor name. Only letters and spaces are allowed.",
    guarantorPhone: validationPatterns.guarantorPhone.test(
      formData.guarantorPhone
    )
      ? null
      : "Invalid guarantor phone number. Between 10 and 15 digits are required.",
    guarantorCnic: validationPatterns.guarantorCnic.test(formData.guarantorCnic)
      ? null
      : "Invalid guarantor CNIC. Exactly 13 digits are required.",
    street: validationPatterns.address.street.test(formData.address.street)
      ? null
      : "Invalid street address.",
    city: validationPatterns.address.city.test(formData.address.city)
      ? null
      : "Invalid city name. Only letters and spaces are allowed.",
    country: validationPatterns.address.country.test(formData.address.country)
      ? null
      : "Invalid country name. Only letters and spaces are allowed.",
    password: validationPatterns.password.test(formData.password)
      ? null
      : "Invalid password. At least 10 characters with at least one letter, one number are required and a special case letter required",
    // confirmPassword:
    //   formData.password.trim().toLowerCase() ===
    //   formData.confirmPassword.trim().toLowerCase()
    //     ? null
    //     : "Passwords do not match.",
  };

  const errors = Object.values(validationResults).filter(
    (error) => error !== null
  );

  return {
    isValid: errors.length === 0,
    errors: validationResults,
  };
};
export { validateFormData };
