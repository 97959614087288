import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import "./redirect.css";

function RedirectUsers() {
  const navigate = useNavigate();
  const [countdown, setCountdown] = useState(10);

  useEffect(() => {
    const timer = setTimeout(() => {
      navigate("/");
    }, 10000);

    return () => clearTimeout(timer); // Cleanup the timer if the component unmounts
  }, [navigate]);

  useEffect(() => {
    const countdownInterval = setInterval(() => {
      setCountdown((prevCount) => prevCount - 1);
    }, 1000);

    return () => clearInterval(countdownInterval); // Cleanup the interval if the component unmounts
  }, []);

  return (
    <div className="redirect-main-container">
      <div className="redirect-content-container">
        <AiOutlineLoading3Quarters className="icon-rotate" size={52} />
        <h3>Redirecting in... {countdown}</h3>
        <h1>You're not logged in, redirecting you to Home Page</h1>
      </div>
    </div>
  );
}

export default RedirectUsers;
