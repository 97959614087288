import {
  signInWithPopup,
  GoogleAuthProvider,
  isSignInWithEmailLink,
  signInWithEmailLink,
  sendSignInLinkToEmail,
  RecaptchaVerifier,
} from "firebase/auth";
import { auth, provider } from "../firebase.config";
import { toast } from "react-toastify";

export const handleGoogleSignIn = async () => {
  try {
    const result = await signInWithPopup(auth, provider);
    const credential = GoogleAuthProvider.credentialFromResult(result);
    const token = credential.accessToken;
    const user = result.user;
    console.log("Google Sign-In successful", user);
    return user;
  } catch (error) {
    console.error("Google Sign-In error", error);
  }
};

export const handleFbSignIn = () => {
  console.log("fb clicked");
};

export const handleEmailSignIn = async (email) => {
  console.log(`email: ${email}`);
  const actionCodeSettings = {
    url: window.location.origin + "/complete-signin",
    handleCodeInApp: true,
    iOS: {
      bundleId: "pal.rent",
    },
    android: {
      packageName: "pal.rent",
      installApp: true,
      minimumVersion: "12",
    },
    dynamicLinkDomain: "pal.rent",
  };

  try {
    await sendSignInLinkToEmail(auth, email, actionCodeSettings);
    window.localStorage.setItem("emailForSignIn", email);
    console.log("Email sign-in link sent");
  } catch (error) {
    console.error("Error sending email sign-in link:", error);
    if (error.code === "auth/network-request-failed") {
      toast.error(
        "Network error. Please check your internet connection and try again."
      );
    } else {
      toast.error("Failed to send email sign-in link. Please try again.");
    }
    throw error;
  }
};

export const completeEmailSignIn = async (emailLink) => {
  const email = window.localStorage.getItem("emailForSignIn");
  if (!email) {
    toast.error("No email found for sign-in. Please try again.");
    return;
  }

  if (isSignInWithEmailLink(auth, emailLink)) {
    try {
      const result = await signInWithEmailLink(auth, email, emailLink);
      window.localStorage.removeItem("emailForSignIn");
      console.log("Email Sign-In successful", result.user);
      return result.user;
    } catch (error) {
      console.error("Error completing email sign-in:", error);
      if (error.code === "auth/network-request-failed") {
        toast.error(
          "Network error. Please check your internet connection and try again."
        );
      } else {
        toast.error("Failed to complete email sign-in. Please try again.");
      }
      throw error;
    }
  } else {
    console.error("Invalid email sign-in link");
    throw new Error("Invalid email sign-in link");
  }
};
