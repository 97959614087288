import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useMemo,
} from "react";

// Create a context
const AuthContext = createContext();

// Create a provider component
export const AuthProvider = ({ children }) => {
  const [authData, setAuthData] = useState(null);

  useEffect(() => {
    const storedAuthData = window.localStorage.getItem("authData");
    if (storedAuthData) {
      setAuthData(JSON.parse(storedAuthData));
    }
  }, []);

  const login = (userData) => {
    setAuthData(userData);
    localStorage.setItem("authData", JSON.stringify(userData));
  };

  const logout = () => {
    setAuthData(null);
    localStorage.removeItem("authData");
  };

  const updateSocialData = (socialData) => {
    window.localStorage.setItem("socialData", socialData);
  };

  // Memoize the role-based authentication values
  const authenticatedSuperAdmin = useMemo(
    () => authData?.data?.role === 0,
    [authData]
  );
  const authenticatedAdmin = useMemo(
    () => authData?.data?.role === 1,
    [authData]
  );
  const authenticatedUser = useMemo(
    () => authData?.data?.role === 2,
    [authData]
  );
  return (
    <AuthContext.Provider
      value={{
        authData,
        login,
        logout,
        updateSocialData,
        authenticatedSuperAdmin,
        authenticatedAdmin,
        authenticatedUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

// Create a custom hook to consume the context
export const useAuth = () => {
  return useContext(AuthContext);
};
