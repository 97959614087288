import React from "react";
import "./hostLanguage.css";

const HostLanguage = () => {
  return (
    <div className="host-language-container">
      <h1>Host Language</h1>
      <div className="host-language-checkbox-container">
        <div className="checkbox-column">
          <label>
            <input type="checkbox" /> Wifi
          </label>
          <label>
            <input type="checkbox" /> Washer
          </label>
          <label>
            <input type="checkbox" /> Air conditioning
          </label>
        </div>
        <div className="checkbox-column">
          <label>
            <input type="checkbox" /> Kitchen
          </label>
          <label>
            <input type="checkbox" /> Dryer
          </label>
          <label>
            <input type="checkbox" /> Heating
          </label>
        </div>
      </div>
      <a className="option-link">Show more</a>
    </div>
  );
};

export default HostLanguage;
