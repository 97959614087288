import React, { useState } from "react";
import "./dashboard.css";
import Dropdown from "../../../assets/svgs/dropdown.svg";
import { useNavigate } from "react-router-dom";

const AdminDash = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const navigate = useNavigate();

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <div className="admin-dash-wrapper">
      <div className="admin-dash-container">
        <button className="dash-button">Reports</button>
        <button
          className="dash-button"
          onClick={() => {
            navigate("/dashboard/listings");
          }}
        >
          Listings
        </button>
        <button className="dash-button">Inbox</button>
        <div className="menu-container">
          <button className="dash-button" onClick={toggleMenu}>
            Menu <img src={Dropdown} />
          </button>
          {menuOpen && (
            <div className="dropdown-menu">
              <button className=" dash-button">Option 1</button>
              <button className=" dash-button">Option 2</button>
              <button className=" dash-button">Option 3</button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AdminDash;
