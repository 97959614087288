import React, { useState, useRef, useEffect } from "react";
import CountriesData from "../../staticData/countriesData";
import { useAuth } from "../../context/AuthContext";
import { URL } from "../../route";
import axios from "axios";
import NumberConfirmation from "../../components/numberConfirmation/numberConfirmation";
import MoreOptionsDialog from "../../components/moreOptionsDialog/moreOptionsDialog";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { auth } from "../../firebase.config";
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import SocialSignIn from "../../components/socialLogin/socialSignIn";
import googleIcon from "../../assets/svgs/google.svg";
import fbIcon from "../../assets/svgs/facebook.svg";
import emailIcon from "../../assets/svgs/email.svg";
import passwordIcon from "../../assets/svgs/password.svg";
import "./login.css";

import {
  handleEmailSignIn,
  handleFbSignIn,
  handleGoogleSignIn,
} from "../../utils/loginUtils";
import { func } from "prop-types";
import { DropDownIconSvg } from "../../components/svgs/rentpal";

const Login = () => {
  const [countryCode, setCountryCode] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [password, setPassword] = useState("");
  const [currentDialog, setCurrentDialog] = useState("login");
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [otp, setOtp] = useState("");
  const [captchaVerified, setCaptchaVerified] = useState(false); // New state to track captcha verification

  const navigate = useNavigate();
  const { authData, login, logout, updateSocialData } = useAuth();
  if (!authData) {
    console.log("authData not found...");
  }

  // console.log(email);
  useEffect(() => {
    onCaptchaVerify();
  }, []);
  //
  function onCaptchaVerify() {
    if (!window.recaptchaVerifier) {
      window.recaptchaVerifier = new RecaptchaVerifier(
        auth,
        "recaptcha-container",
        {
          size: "invisible",
          callback: () => {
            // ReCAPTCHA solved, set flag
            setCaptchaVerified(true);
          },
          "expired-callback": () => {
            // Reset reCAPTCHA if it expires
            console.log("reCAPTCHA expired");
            setCaptchaVerified(false);
          },
        }
      );
    }
  }

  const handlePhoneNumberSignIn = () => {
    if (!captchaVerified) {
      toast.error("Please complete the reCAPTCHA");
      return;
    }

    setLoading(true);

    const appVerifier = window.recaptchaVerifier;
    const formatPh = countryCode + phoneNumber;
    console.log(formatPh);

    signInWithPhoneNumber(auth, formatPh, appVerifier)
      .then((confirmationResult) => {
        window.confirmationResult = confirmationResult;
        setLoading(false);
        toast.success("OTP sent successfully!");
        setCurrentDialog("numberConfirmation");
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
        toast.error("Failed to send OTP. Please try again.");
      });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(countryCode + phoneNumber);

    // Trigger reCAPTCHA verification
    window.recaptchaVerifier.render().then(function (widgetId) {
      grecaptcha.reset(widgetId);
      grecaptcha.execute(widgetId).then(() => {
        // Once reCAPTCHA is verified, proceed with phone number sign-in
        handlePhoneNumberSignIn();
      });
    });
  };

  const handleCountryCodeChange = (e) => {
    const selectedDialCode = e.target.value;
    setCountryCode(selectedDialCode);
  };

  const handlePhoneNumberChange = (e) => {
    setPhoneNumber(e.target.value);
  };

  const handleOtpSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const result = await window.confirmationResult.confirm(otp);
      toast.success("OTP verified successfully!");
      setLoading(false);

      // Set auth data here
      const userData = {
        uid: result.user.uid,
        phoneNumber: result.user.phoneNumber,
      };
      // Update auth context with user data

      updateSocialData(userData);

      // Redirect or perform further actions here
      setTimeout(() => {
        navigate("/");
      }, 2000);
    } catch (error) {
      console.error("Error verifying OTP:", error);
      setLoading(false);
      toast.error("Invalid OTP. Please try again.");
    }
  };

  const handlePasswordSignIn = (e) => {
    console.log(phoneNumber, password);
    axios
      .post(`${URL}/api/v1/auth/admin/verifyManager`, {
        name: phoneNumber,
        password: password,
      })
      .then(function (response) {
        console.log(response);
        window.localStorage.setItem("authData", JSON.stringify(response.data));

        navigate("/");
      })
      .catch(function (error) {
        console.log(error);
        toast.error(error);
      });
  };
  const handleClose = () => {
    setCurrentDialog("null");
  };

  return (
    <div className="login-form-main-container">
      <ToastContainer />
      <div id="recaptcha-container"></div>
      {currentDialog === "login" && (
        <div className="login-form-container">
          <div className="login-form-title-svg">
            <svg
              className="login-close-svg"
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 22 22"
              fill="none"
              onClick={handleClose}
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M20.9447 1.35149C21.2539 1.04232 21.2539 0.541052 20.9447 0.23188C20.6356 -0.0772932 20.1343 -0.0772932 19.8251 0.23188L10.6048 9.45222L1.38449 0.231946C1.07532 -0.0772264 0.574049 -0.0772267 0.264877 0.231946C-0.0442962 0.541119 -0.0442958 1.04239 0.264877 1.35156L9.48516 10.5718L0.231879 19.8251C-0.077293 20.1343 -0.0772933 20.6356 0.231879 20.9447C0.541052 21.2539 1.04232 21.2539 1.35149 20.9447L10.6048 11.6915L19.8581 20.9448C20.1673 21.254 20.6686 21.254 20.9777 20.9448C21.2869 20.6356 21.2869 20.1344 20.9777 19.8252L11.7244 10.5718L20.9447 1.35149Z"
                fill="black"
              />
            </svg>
            <h3 className="login-form-title">Log in or Sign up</h3>
          </div>
          <div className="break-line"></div>
          <div className="login-form">
            <h1 className="login-heading">Welcome to Rentpal</h1>
            <div>
              <form
                onSubmit={(e) => handleSubmit(e)}
                className="form-main-container"
              >
                <div className="field-1-container">
                  <div className="field-label">Country Code</div>
                  <select
                    className="field-1"
                    value={countryCode}
                    onChange={handleCountryCodeChange}
                  >
                    {CountriesData.map((country) => (
                      <option key={country.code} value={country.dial_code}>
                        {country.name} ({country.dial_code})
                      </option>
                    ))}
                  </select>
                  <DropDownIconSvg />
                </div>
                <input
                  type="tel"
                  id="phoneNumber"
                  name="phoneNumber"
                  placeholder="Phone Number"
                  value={phoneNumber}
                  onChange={handlePhoneNumberChange}
                  className="input-field-2"
                  autoComplete="tel-national"
                />
                <h6 className="login-form-infrom">
                  We’ll call or text you to confirm your number. Standard
                  message and data rates apply.
                </h6>
                <a className="login-form-link">Privacy Policy</a>
                <button type="submit" id="submit-btn-primary">
                  Continue
                </button>
              </form>
            </div>
          </div>
          <div className="break-line"></div>
          <div className="social-signIn-container">
            <SocialSignIn
              socialPlatform={"Google"}
              icon={googleIcon}
              onClick={handleGoogleSignIn}
            />
            <SocialSignIn
              socialPlatform={"Facebook"}
              icon={fbIcon}
              onClick={handleFbSignIn}
            />
            <SocialSignIn
              socialPlatform={"eMail"}
              icon={emailIcon}
              onClick={() => setCurrentDialog("enter-email")}
            />
            <SocialSignIn
              socialPlatform={"Password"}
              icon={passwordIcon}
              onClick={() => setCurrentDialog("enter-password")}
            />
          </div>
        </div>
      )}

      {currentDialog === "numberConfirmation" && (
        <NumberConfirmation
          setCurrentDialog={setCurrentDialog}
          otp={otp}
          setOtp={setOtp}
          handleOtpSubmit={handleOtpSubmit}
        />
      )}

      {currentDialog === "moreOptions" && (
        <MoreOptionsDialog setCurrentDialog={setCurrentDialog} />
      )}
      {currentDialog === "enter-email" && (
        <>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleEmailSignIn(email);
            }}
          >
            <label>Enter your email</label>
            <input value={email} onChange={(e) => setEmail(e.target.value)} />
            <button type="submit">Submit</button>
          </form>
        </>
      )}
      {currentDialog === "enter-password" && (
        <>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              handlePasswordSignIn(phoneNumber, password);
            }}
          >
            <label>Enter your Phone Number with country code</label>
            <input
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
            />
            <input
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <button type="submit">Submit</button>
          </form>
        </>
      )}
    </div>
  );
};

export default Login;
