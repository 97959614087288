import { Route, Routes } from "react-router-dom";
// import { AuthProvider } from "./context/AuthContext";
import Home from "./pages/home/Home";
import Login from "./pages/login/login";
import Property from "./pages/managers/propertyForm/propertyForm";
import Managers from "./pages/managers/viewResidents/managers";
import ViewAllProperties from "./pages/managers/viewAllProperties/viewAllProperties";
import PropertyUpdate from "./pages/managers/propertyUpdate/propertyUpdate";
import ViewProperty from "./pages/managers/viewProperty/viewProperty";
// import Navbar from "./components/navbar/navbar";
import SignUp from "./pages/signup/signUp";
// import SearchBar from "./components/searchBar/searchBar"
import CompleteSignIn from "./pages/completeSignIn/completeSignIn";
import RedirectUsers from "./pages/redirectUsers/redirect";
import PrivateRoute from "./routes/privateRoutes";
import PageNotFound from "./pages/404";
import Layout from "./components/layout/layout";
import AdminDashboard from "./pages/managers/dahboard/adminDashboard";
import Listings from "./pages/managers/listings/listings";
//es-lint
import AddListings from "./pages/managers/addListings/addlistings";
import Testing from "./pages/testing/testing";
import MeterReadings from "./pages/meterReadings/meterReadings";

function App() {
  return (
    <>
      <Layout>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route
            path="/meter-readings"
            element={<MeterReadings espId="device1" />}
          />
          <Route path="/login" element={<Login />} />
          <Route path="/viewAllProperties" element={<ViewAllProperties />} />

          <Route path="/complete-signin" element={<CompleteSignIn />} />
          <Route path="/redirect" element={<RedirectUsers />} />
          <Route path="*" element={<PageNotFound />} />
          <Route path="/dashboard" element={<AdminDashboard />} />
          <Route path="/testing" element={<Testing />} />

          <Route
            path="/signUp"
            element={
              <PrivateRoute>
                <SignUp />
              </PrivateRoute>
            }
          />
          <Route
            path="/dashboard/listings"
            element={
              <PrivateRoute>
                <Listings />
              </PrivateRoute>
            }
          />
          <Route
            path="/dashboard/listings/:uuid/add-listings"
            element={
              <PrivateRoute>
                <AddListings />
              </PrivateRoute>
            }
          />

          {/* <Route
            path="/dashboard/listings/add-listings"
            element={<AddListings />}
          /> */}
          <Route
            path="/addProperty"
            element={
              <PrivateRoute>
                <Property />
              </PrivateRoute>
            }
          />
          <Route
            path="/addResident"
            element={
              <PrivateRoute>
                <Managers />
              </PrivateRoute>
            }
          />
          <Route
            path="/viewProperty"
            element={
              <PrivateRoute>
                <ViewProperty />
              </PrivateRoute>
            }
          />
          <Route
            path="/updateProperty"
            element={
              <PrivateRoute>
                <PropertyUpdate />
              </PrivateRoute>
            }
          />
        </Routes>
      </Layout>
    </>
  );
}

export default App;
