import React from "react";
import { ReactComponent as Wifi } from "../../assets/svgs/wifi.svg";
import "./propertyType.css";

const PropertyType = () => {
  return (
    <div className="propertyType-main-container">
      <h1>Property Type</h1>
      <div className="property-options-container">
        <div className="property-icon-content-container">
          <Wifi />
          <h2>House</h2>
        </div>
        <div className="property-icon-content-container">
          <Wifi />
          <h2>Apartment</h2>
        </div>
        <div className="property-icon-content-container">
          <Wifi />
          <h2>Guest House</h2>
        </div>
      </div>
    </div>
  );
};

export default PropertyType;
