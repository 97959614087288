import React from "react";

export const Property = ({ icon, label }) => {
  return (
    <div className="property-icon-text-box">
      <p className="property-icons-text">{label}</p>
      {icon}
    </div>
  );
};
