// BookingOptions.js
import React, { useState } from "react";
import "./bookingOptions.css";
import ToogleButton from "../toogleButton/toogleButton";
import ServiceAnimal from "../serviceAnimal/serviceAnimal";

const BookingOptions = () => {
  const [showComponent, setShowComponent] = useState(null);

  const handlePetServiceClick = () => {
    setShowComponent("serviceAnimal");
  };

  const handleClose = () => {
    setShowComponent("facilities");
  };

  return (
    <div className="booking-options-container">
      <h1>Booking Options</h1>
      <div className="options-container">
        <div className="options-content">
          <h1>Instant Book</h1>
          <h2>Listings you can book without waiting for Host approval</h2>
        </div>
        <ToogleButton />
      </div>
      <div className="options-container">
        <div className="options-content">
          <h1>Self check-in</h1>
          <h2>Easy access to the property once you arrive</h2>
        </div>
        <ToogleButton />
      </div>
      <div className="options-container">
        <div className="options-content">
          <h1>Allows pets</h1>
          <a onClick={handlePetServiceClick}>Bringing a service animal?</a>
        </div>
        <ToogleButton />
      </div>
      {showComponent === "serviceAnimal" && (
        <ServiceAnimal onClose={handleClose} />
      )}
    </div>
  );
};

export default BookingOptions;
