import React from "react";
import "./socialSignIn.css";

const socialSignIn = (props) => {
  return (
    <>
      <div className="social-signin-main" onClick={props.onClick}>
        <img src={props.icon} className="img-icon" />
        <h1>Continue with {props.socialPlatform}</h1>
        {props.children}
      </div>
    </>
  );
};

export default socialSignIn;
