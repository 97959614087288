import "./dialogues.css";

const BoxSelect = (props) => {
  return (
    <div className="basic-box" onClick={props.onClick}>
      {props.children}
    </div>
  );
};

export { BoxSelect };
