import React, { useState } from "react";
import "./optionsPicker.css";

const OptionsPicker = ({ title, options = [] }) => {
  const [showMore, setShowMore] = useState(false);

  return (
    <>
      <h2 id="optionPicker-title">{title}</h2>
      <div className="options-select-content-main-container">
        {options.map((column, columnIndex) => (
          <div key={columnIndex} className="checkbox-column">
            {Array.isArray(column) ? (
              column.map((option, index) => (
                <label key={index}>
                  <input type="checkbox" /> {option}
                </label>
              ))
            ) : (
              <p>Invalid column format</p>
            )}
          </div>
        ))}
      </div>
    </>
  );
};

export default OptionsPicker;
