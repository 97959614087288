const propertyFormData = [
  {
    category: "Residential",
    subcategory: "Detached House",
    sections: [
      {
        title: "Basic Information",
        fields: [
          { label: "Property Name/Title", type: "text" },
          { label: "Address", type: "text" },
          { label: "Rent/Sale Price", type: "number" },
          { label: "Currency", type: "select", options: ["USD", "EUR", "PKR"] },
          { label: "Area", type: "number" },
          {
            label: "Unit",
            type: "select",
            options: ["Marla", "Kanal", "Sq. meters"],
          },
          { label: "Number of Bedrooms", type: "number" },
          { label: "Number of Bathrooms", type: "number" },
          { label: "Number of Floors", type: "number" },
          { label: "Year Built", type: "number" },
        ],
      },
      {
        title: "Amenities",
        fields: [
          { label: "Garage/Parking", type: "checkbox" },
          { label: "Garden", type: "checkbox" },
          { label: "Swimming Pool", type: "checkbox" },
          { label: "Fireplace", type: "checkbox" },
          { label: "Air Conditioning", type: "checkbox" },
          { label: "Heating System", type: "checkbox" },
          { label: "Security System", type: "checkbox" },
        ],
      },
      {
        title: "Utilities",
        fields: [
          { label: "Water Supply", type: "checkbox" },
          { label: "Electricity", type: "checkbox" },
          { label: "Gas", type: "checkbox" },
          { label: "Internet", type: "checkbox" },
        ],
      },
      {
        title: "Additional Features",
        fields: [
          { label: "Furnished/Unfurnished", type: "checkbox" },
          { label: "Pet-Friendly", type: "checkbox" },
          { label: "Smoking Allowed", type: "checkbox" },
          { label: "Nearby Schools", type: "checkbox" },
          { label: "Nearby Public Transport", type: "checkbox" },
        ],
      },
    ],
  },
  {
    category: "Residential",
    subcategory: "Flat/Apartment",
    sections: [
      {
        title: "Basic Information",
        fields: [
          { label: "Property Name/Title", type: "text" },
          { label: "Address", type: "text" },
          { label: "Rent/Sale Price", type: "number" },
          { label: "Currency", type: "select", options: ["USD", "EUR", "PKR"] },
          { label: "Area", type: "number" },
          {
            label: "Unit",
            type: "select",
            options: ["Marla", "Kanal", "Sq. meters"],
          },
          { label: "Floor Number", type: "number" },
          { label: "Number of Bedrooms", type: "number" },
          { label: "Number of Bathrooms", type: "number" },
          { label: "Year Built", type: "number" },
        ],
      },
      {
        title: "Amenities",
        fields: [
          { label: "Elevator", type: "checkbox" },
          { label: "Balcony", type: "checkbox" },
          { label: "Parking", type: "checkbox" },
          { label: "Gym", type: "checkbox" },
          { label: "Swimming Pool", type: "checkbox" },
          { label: "Security/Doorman", type: "checkbox" },
          { label: "Air Conditioning", type: "checkbox" },
          { label: "Heating System", type: "checkbox" },
        ],
      },
      {
        title: "Utilities",
        fields: [
          { label: "Water Supply", type: "checkbox" },
          { label: "Electricity", type: "checkbox" },
          { label: "Gas", type: "checkbox" },
          { label: "Internet", type: "checkbox" },
        ],
      },
      {
        title: "Additional Features",
        fields: [
          { label: "Furnished/Unfurnished", type: "checkbox" },
          { label: "Pet-Friendly", type: "checkbox" },
          { label: "Smoking Allowed", type: "checkbox" },
          { label: "Nearby Schools", type: "checkbox" },
          { label: "Nearby Public Transport", type: "checkbox" },
        ],
      },
    ],
  },
  {
    category: "Residential",
    subcategory: "Guest House",
    sections: [
      {
        title: "Basic Information",
        fields: [
          { label: "Property Name/Title", type: "text" },
          { label: "Address", type: "text" },
          { label: "Rent/Sale Price", type: "number" },
          { label: "Currency", type: "select", options: ["USD", "EUR", "PKR"] },
          { label: "Area", type: "number" },
          {
            label: "Unit",
            type: "select",
            options: ["Marla", "Kanal", "Sq. meters"],
          },
          { label: "Number of Rooms", type: "number" },
          { label: "Number of Bathrooms", type: "number" },
          { label: "Year Built", type: "number" },
        ],
      },
      {
        title: "Amenities",
        fields: [
          { label: "Parking", type: "checkbox" },
          { label: "Garden", type: "checkbox" },
          { label: "Swimming Pool", type: "checkbox" },
          { label: "Air Conditioning", type: "checkbox" },
          { label: "Heating System", type: "checkbox" },
          { label: "Security System", type: "checkbox" },
        ],
      },
      {
        title: "Utilities",
        fields: [
          { label: "Water Supply", type: "checkbox" },
          { label: "Electricity", type: "checkbox" },
          { label: "Gas", type: "checkbox" },
          { label: "Internet", type: "checkbox" },
        ],
      },
      {
        title: "Additional Features",
        fields: [
          { label: "Furnished/Unfurnished", type: "checkbox" },
          { label: "Pet-Friendly", type: "checkbox" },
          { label: "Smoking Allowed", type: "checkbox" },
          { label: "Nearby Attractions", type: "checkbox" },
        ],
      },
    ],
  },
  {
    category: "Residential",
    subcategory: "Hostel",
    sections: [
      {
        title: "Basic Information",
        fields: [
          { label: "Property Name/Title", type: "text" },
          { label: "Address", type: "text" },
          { label: "Rent/Sale Price (Per Bed/Room)", type: "number" },
          { label: "Currency", type: "select", options: ["USD", "EUR", "PKR"] },
          { label: "Number of Rooms", type: "number" },
          { label: "Number of Beds", type: "number" },
          { label: "Number of Bathrooms", type: "number" },
          { label: "Year Built", type: "number" },
        ],
      },
      {
        title: "Amenities",
        fields: [
          { label: "Shared Kitchen", type: "checkbox" },
          { label: "Common Room", type: "checkbox" },
          { label: "Laundry Facilities", type: "checkbox" },
          { label: "Security System", type: "checkbox" },
          { label: "Air Conditioning", type: "checkbox" },
          { label: "Heating System", type: "checkbox" },
        ],
      },
      {
        title: "Utilities",
        fields: [
          { label: "Water Supply", type: "checkbox" },
          { label: "Electricity", type: "checkbox" },
          { label: "Gas", type: "checkbox" },
          { label: "Internet", type: "checkbox" },
        ],
      },
      {
        title: "Additional Features",
        fields: [
          { label: "Furnished/Unfurnished", type: "checkbox" },
          { label: "Pet-Friendly", type: "checkbox" },
          { label: "Smoking Allowed", type: "checkbox" },
          { label: "Nearby Schools", type: "checkbox" },
          { label: "Nearby Public Transport", type: "checkbox" },
        ],
      },
    ],
  },
  {
    category: "Commercial",
    subcategory: "Shop",
    sections: [
      {
        title: "Basic Information",
        fields: [
          { label: "Property Name/Title", type: "text" },
          { label: "Address", type: "text" },
          { label: "Rent/Sale Price", type: "number" },
          { label: "Currency", type: "select", options: ["USD", "EUR", "PKR"] },
          { label: "Area", type: "number" },
          {
            label: "Unit",
            type: "select",
            options: ["Marla", "Kanal", "Sq. meters"],
          },
          { label: "Floor Number", type: "number" },
          { label: "Year Built", type: "number" },
        ],
      },
      {
        title: "Amenities",
        fields: [
          { label: "Parking", type: "checkbox" },
          { label: "Storage Room", type: "checkbox" },
          { label: "Air Conditioning", type: "checkbox" },
          { label: "Heating System", type: "checkbox" },
          { label: "Security System", type: "checkbox" },
        ],
      },
      {
        title: "Utilities",
        fields: [
          { label: "Water Supply", type: "checkbox" },
          { label: "Electricity", type: "checkbox" },
          { label: "Gas", type: "checkbox" },
          { label: "Internet", type: "checkbox" },
        ],
      },
      {
        title: "Additional Features",
        fields: [
          { label: "Nearby Public Transport", type: "checkbox" },
          { label: "High Foot Traffic Area", type: "checkbox" },
          { label: "Display Windows", type: "checkbox" },
        ],
      },
    ],
  },
  {
    category: "Commercial",
    subcategory: "Floor",
    sections: [
      {
        title: "Basic Information",
        fields: [
          { label: "Property Name/Title", type: "text" },
          { label: "Address", type: "text" },
          { label: "Rent/Sale Price", type: "number" },
          { label: "Currency", type: "select", options: ["USD", "EUR", "PKR"] },
          { label: "Area", type: "number" },
          {
            label: "Unit",
            type: "select",
            options: ["Marla", "Kanal", "Sq. meters"],
          },
          { label: "Floor Number", type: "number" },
          { label: "Number of Rooms/Units", type: "number" },
          { label: "Year Built", type: "number" },
        ],
      },
      {
        title: "Amenities",
        fields: [
          { label: "Elevator", type: "checkbox" },
          { label: "Parking", type: "checkbox" },
          { label: "Security System", type: "checkbox" },
          { label: "Air Conditioning", type: "checkbox" },
          { label: "Heating System", type: "checkbox" },
        ],
      },
      {
        title: "Utilities",
        fields: [
          { label: "Water Supply", type: "checkbox" },
          { label: "Electricity", type: "checkbox" },
          { label: "Gas", type: "checkbox" },
          { label: "Internet", type: "checkbox" },
        ],
      },
      {
        title: "Additional Features",
        fields: [
          { label: "Nearby Public Transport", type: "checkbox" },
          { label: "Suitable for Offices/Retail", type: "checkbox" },
        ],
      },
    ],
  },
  {
    category: "Industrial",
    subcategory: "Warehouse",
    sections: [
      {
        title: "Basic Information",
        fields: [
          { label: "Property Name/Title", type: "text" },
          { label: "Address", type: "text" },
          { label: "Rent/Sale Price", type: "number" },
          { label: "Currency", type: "select", options: ["USD", "EUR", "PKR"] },
          { label: "Area", type: "number" },
          {
            label: "Unit",
            type: "select",
            options: ["Marla", "Kanal", "Sq. meters"],
          },
          { label: "Ceiling Height", type: "number" },
          { label: "Year Built", type: "number" },
        ],
      },
      {
        title: "Amenities",
        fields: [
          { label: "Loading Dock", type: "checkbox" },
          { label: "Parking", type: "checkbox" },
          { label: "Security System", type: "checkbox" },
        ],
      },
      {
        title: "Utilities",
        fields: [
          { label: "Water Supply", type: "checkbox" },
          { label: "Electricity", type: "checkbox" },
          { label: "Gas", type: "checkbox" },
          { label: "Internet", type: "checkbox" },
        ],
      },
      {
        title: "Additional Features",
        fields: [
          { label: "Fenced Area", type: "checkbox" },
          { label: "Temperature Control", type: "checkbox" },
        ],
      },
    ],
  },
];

export default propertyFormData;
