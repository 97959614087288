import React from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "./Cards.css";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

export default function Cards({ cardsData, loading, onClick }) {
  return (
    <div className="cards-container">
      {loading
        ? Array.from({ length: 5 }).map((_, index) => (
            <div key={index} className="card">
              <Skeleton className="skeleton-image" />
              <Skeleton className="skeleton-header" />
            </div>
          ))
        : cardsData.map((property) => (
            <div key={property._id} className="card">
              <div className="card-body">
                {property.pictures && property.pictures.length > 0 ? (
                  <Carousel
                    showThumbs={false}
                    showIndicators={true}
                    infiniteLoop
                    autoPlay
                  >
                    {property.pictures.map((picture, index) => (
                      <div key={index} className="carousel-image-container">
                        <img
                          src={picture}
                          alt={`Property ${index + 1}`}
                          className="card-image"
                        />
                      </div>
                    ))}
                  </Carousel>
                ) : (
                  <p className="no-images">No images available</p>
                )}
              </div>
              <div className="card-header" onClick={onClick}>
                <h2 className="building-name">
                  {property?.formData?.specs?.buildingName}
                  <span>,</span>
                  {property?.formData?.specs?.location || "Lahore"}
                </h2>
              </div>
            </div>
          ))}
    </div>
  );
}
