import React, { useState } from "react";
import "./placeType.css";

const PlaceType = () => {
  const [selected, setSelected] = useState("Any type");

  return (
    <div className="place-type-main-container">
      <h1>PlaceType</h1>
      <div className="place-type-buttons-container">
        <div className="buttons-type-container">
          <button
            className={`button-type ${
              selected === "Any type" && (
                <h1>Search rooms, entire homes, or any type of place</h1>
              )
                ? "selected"
                : ""
            }`}
            onClick={() => setSelected("Any type")}
          >
            <span>Any type</span>
          </button>
          <button
            className={`button-type ${
              selected === "Room" && (
                <h1>Search rooms, entire homes, or any type of place</h1>
              )
                ? "selected"
                : ""
            }`}
            onClick={() => setSelected("Room")}
          >
            <span>Room</span>
          </button>
          <button
            className={`button-type ${
              selected === "Entire home" && (
                <h1>Search rooms, entire homes, or any type of place</h1>
              )
                ? "selected"
                : ""
            }`}
            onClick={() => setSelected("Entire home")}
          >
            <span>Entire home</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default PlaceType;
