import React, { useState, useEffect, useRef } from "react";
import "./navbar.css";
import LoginDialog from "../loginDialog/loginDialog";
// import SearchBar from "../../components/searchBar/searchBar";
import { useNavigate, useLocation } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";
import {
  HamburgerSVG,
  RentPalSVG,
  RLogoSVG,
  UserIconSVG,
} from "../svgs/rentpal";
import AdminDash from "../admin/dashboard/dashboard";

const Navbar = () => {
  const {
    authData,
    login,
    logout,
    authenticatedSuperAdmin,
    authenticatedAdmin,
    authenticatedUser,
  } = useAuth();

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const dialogRef = useRef(null);
  const navigate = useNavigate();
  const location = useLocation(); // Add this line

  const handleClick = () => {
    setIsDialogOpen(!isDialogOpen);
  };

  const handleClickOutside = (event) => {
    if (dialogRef.current && !dialogRef.current.contains(event.target)) {
      setIsDialogOpen(false);
    }
  };

  const handleHomeClick = () => {
    navigate("/");
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <>
      <div className="navbar-main-container">
        <div className="navbar-content-container">
          <div className="logo-main-container" onClick={handleHomeClick}>
            {location.pathname === "/dashboard" ? (
              <RLogoSVG />
            ) : (
              <div className="navbar-title-svgs-container">
                <RentPalSVG />
                <div className="navbar-title ">
                  &nbsp; &mdash; Simplifying property management
                </div>
              </div>
            )}
          </div>
          <div className="navbar-svgs-container">
            <HamburgerSVG handleClick={handleClick} />
            <UserIconSVG />
            {isDialogOpen && <LoginDialog dialogRef={dialogRef} />}
          </div>
          {location.pathname === "/dashboard" && ( // Add this line
            <div className="dashboard-container">
              {authenticatedUser && <div>AUTHENTICATED USER dashboard</div>}
              {authenticatedAdmin && <AdminDash />}
              {authenticatedSuperAdmin && <AdminDash />}
            </div>
          )}{" "}
          {/* Add this line */}
        </div>
      </div>
      {/* <SearchBar /> */}
    </>
  );
};

export default Navbar;
