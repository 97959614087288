import React, { useState, useEffect, useMemo } from "react";
import { ReactComponent as Filter } from "../../assets/svgs/filter.svg";
import { ReactComponent as RightArrow } from "../../assets/svgs/rightArrow.svg";
import { facilitiesList } from "./facilitiesList";
import { Facility } from "./facility";
import FacilitiesDialog from "../facilitiesDialog/facilitiesDialog";
import "./facilities.css";
import ToggleButton from "../toogleButton/toogleButton";

export const Facilities = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(1);
  const [showFilterDialog, setShowFilterDialog] = useState(false);

  const updateItemsPerPage = () => {
    if (window.innerWidth >= 1200) {
      setItemsPerPage(12);
    } else if (window.innerWidth >= 768) {
      setItemsPerPage(5);
    } else {
      setItemsPerPage(1);
    }
  };

  useEffect(() => {
    updateItemsPerPage();
    window.addEventListener("resize", updateItemsPerPage);
    return () => window.removeEventListener("resize", updateItemsPerPage);
  }, [itemsPerPage]);

  const handlePrevClick = () => {
    setCurrentIndex((prevIndex) => Math.max(prevIndex - itemsPerPage, 0));
  };

  const handleNextClick = () => {
    setCurrentIndex((prevIndex) =>
      Math.min(prevIndex + itemsPerPage, facilitiesList.length - itemsPerPage)
    );
  };

  const handleFilterClick = () => {
    setShowFilterDialog((prev) => !prev);
    console.log(showFilterDialog);
  };

  const handleCloseDialog = () => {
    setShowFilterDialog(false);
  };

  const filterIconStyle = useMemo(
    () => ({
      height: "2.5vh",
    }),
    []
  );

  const visibleFacilities = useMemo(() => {
    return facilitiesList.slice(currentIndex, currentIndex + itemsPerPage);
  }, [currentIndex, itemsPerPage]);

  const isPrevDisabled = currentIndex === 0;
  const isNextDisabled = currentIndex >= facilitiesList.length - itemsPerPage;

  return (
    <div className="facilities-main-container">
      <div className="facilities-slider-container">
        <div className="swiper-arrow-left">
          {!isPrevDisabled && <RightArrow onClick={handlePrevClick} />}
        </div>
        <div className="facility-item-container">
          {visibleFacilities.map((facility, index) => (
            <div key={index} className="facility-item">
              <Facility icon={facility.icon} label={facility.label} />
            </div>
          ))}
        </div>
        <div className="swiper-arrow-right">
          {!isNextDisabled && <RightArrow onClick={handleNextClick} />}
        </div>
      </div>

      <div className="filter-container" onClick={handleFilterClick}>
        <Filter style={filterIconStyle} />
        <p className="filter-text">Filters</p>
      </div>
      <div className="tax-container">
        <h1> Display total before taxes</h1>
        <ToggleButton />
      </div>
      {showFilterDialog && <FacilitiesDialog onClose={handleCloseDialog} />}
    </div>
  );
};
