import React from "react";
import PropertyCrud from "../../pages/managers/propertyForm/propertyForm";

export default function UpdateProperty(props) {
  return (
    <>
      <h1>property update</h1>
      <PropertyCrud />
    </>
  );
}
