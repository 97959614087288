import "./progressBar.css";

const ProgressBar = (props) => {
  return (
    <div className="progress-bar-container">
      <div
        className="progress-bar"
        style={{
          width: `${props.progressPercentage || "20"}%`,
          backgroundColor: `${props.backgroundColor || "black"}`,
        }}
      ></div>
    </div>
  );
};

export { ProgressBar };
