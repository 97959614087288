import React, { useState } from "react";
import "./selectionPanel.css";
import OptionsPicker from "../optionsPicker/optionsPicker";

const SelectionsPanel = (props) => {
  const [showMore, setShowMore] = useState(false);
  const guestEntranceAndParking = [
    ["Front Door", "Back Door"],
    ["Driveway", "Street Parking"],
  ];
  const handleShowMore = () => {
    setShowMore((prev) => !prev);
  };

  return (
    <div className="accessibility-features-container">
      <h1>{props.title}</h1>
      <OptionsPicker
        title="Guest Entrance and Parking"
        options={guestEntranceAndParking}
      />

      {showMore && (
        <>
          <OptionsPicker title="Bedroom" options={guestEntranceAndParking} />
          <OptionsPicker title="Bathroom" options={guestEntranceAndParking} />
          <OptionsPicker
            title="Adaptive equipment"
            options={guestEntranceAndParking}
          />
        </>
      )}

      <a className="option-link" onClick={handleShowMore}>
        {showMore ? "Show less" : "Show more"}
      </a>
    </div>
  );
};

export default SelectionsPanel;
