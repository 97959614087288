import React from "react";
import "./adminDashboard.css";
import { BoxSelect } from "../../../components/dialogues/dialogues";
import Exclaimation from "../../../assets/svgs/Exclaimation.svg";
import { useNavigate } from "react-router-dom";

function AdminDashboard() {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/signUp");
  };

  return (
    <div className="admin-dashboard-main">
      <div className="admin-dashboard-content">
        <h1 className="text-primary">Welcome, Muhammad Talha</h1>
        <h1 className="text-secondary">
          Guests can reserve your place 24 hours after you publish—here’s how
          to prepare.
        </h1>
        <br></br>
        <br></br>
        <div className="flexbox">
          <BoxSelect onClick={handleClick}>
            <div>
              <div className="text-danger-container">
                <span className="text-normal">Complete important details</span>
              </div>
              <br />
              <div className="text-danger-container">
                <span className="text-danger">Required to publish</span>
                <img src={Exclaimation} alt="Exclamation Mark" />
              </div>
              <br />
              <span className="text-link-normal">Continue</span>
            </div>
          </BoxSelect>
        </div>
      </div>
      <button className="admin-dashboard-button">
        <h1 className="admin-dashboard-button-text">Complete your listing</h1>
      </button>
      {/* <Verification />
        <PropertyManagement />
        <ResidentManagement />
        <FinancialManagement />
        <ResidentRequests />
        <AlertsNotifications />
        <CommunicationTools /> */}
    </div>
  );
}

export default AdminDashboard;
