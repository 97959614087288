const CityData = [
  {
    name: "Bahwalpur",
  },
  {
    name: "Faislabad",
  },
  {
    name: "Gujranwala",
  },
  {
    name: "Karachi",
  },
  {
    name: "Lahore",
  },
  {
    name: "Multan",
  },
];
export default CityData
