import "./specialDot.css";
import React from "react";

export default function SpecialDot() {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="30"
        height="30"
        viewBox="0 0 30 30"
        className="special-dot"
        style={{
          verticalAlign: "middle",
          marginRight: "1ch",
          marginLeft: "1ch",
        }}
      >
        <circle cx="15" cy="15" r="15" fill="#808080" />
      </svg>
    </>
  );
}
