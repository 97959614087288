import React, { useState } from "react";

const RadioSelect = (props) => {
  const [selected, setSelected] = useState("");
  const bedroomOptions = props.options;

  const handleClick = (value) => {
    setSelected(value);
  };

  return (
    <div className="beds-bathroom-container">
      <p>{props.title}</p>
      <div className="beds-bathroom-options-container">
        {bedroomOptions.map((option) => (
          <span
            key={option}
            onClick={() => handleClick(option)}
            className={`beds-bathroom-options ${
              selected === option ? "selected" : ""
            }`}
          >
            {option}
          </span>
        ))}
      </div>
    </div>
  );
};

export default RadioSelect;
