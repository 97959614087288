import React from "react";

export const Facility = ({ icon, label }) => {
  return (
    <div className="icon-text-box">
      {icon}
      <p className="icons-text">{label}</p>
    </div>
  );
};
