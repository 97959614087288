import React, { useState } from "react";
import axios from "axios";
import AddProperty from "../../../components/propertyForm/addProperty";
import { URL } from "../../../route";
import { useAuth } from "../../../context/AuthContext";

const PropertyCrud = () => {
  const [pics, setPics] = useState([]);
  const [formData, setFormData] = useState({
    specs: {
      facilities: {}, // Initialize facilities as an empty object
    },
  });

  let errorMessage;
  const { authData } = useAuth(); // Get auth data

  const handleSubmit = async (event) => {
    event.preventDefault();
    const query = new FormData();

    // Append facilities individually to avoid "[object Object]"
    if (
      formData.specs.facilities &&
      Object.keys(formData.specs.facilities).length > 0
    ) {
      for (const [key, value] of Object.entries(formData.specs.facilities)) {
        query.append(`formData[specs][facilities][${key}]`, value);
      }
    }

    // Append other specs
    if (formData.specs && Object.keys(formData.specs).length > 0) {
      for (const [key, value] of Object.entries(formData.specs)) {
        if (key !== "facilities") {
          // Skip facilities as it's handled above
          query.append(`formData[specs][${key}]`, value);
        }
      }
    }

    query.append("propertyType", formData.propertyType);
    query.append("propertyClassification", formData.propertyClassification);
    query.append("propertyUnit", formData.propertyUnit);
    // Append user ID
    query.append("user", authData.data._id);
    console.log(authData.data._id);
    // Handle picture data
    if (Array.isArray(pics)) {
      pics.forEach((picture, index) => {
        query.append(`pictures[${index}]`, picture);
      });
    }

    // logFormData(query);

    try {
      const response = await axios.post(
        `${URL}/api/v1/auth/admin/addProperty`,
        query,
        {
          headers: {
            "Content-type": "multipart/form-data",
          },
        }
      );

      const data = response.data;
      if (data) {
        // if successful
        console.log("Successfully uploaded property details!");
        alert("Successfully uploaded property details!");
        // navigate("/");
      } else {
        alert("Incorrect username or password.");
      }
    } catch (error) {
      console.error("There was a problem uploading data:", error);
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleBuildingInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      specs: {
        ...formData.specs,
        [name]: value,
      },
    });
  };

  const handleFacilitiesChange = (event) => {
    const { name, checked } = event.target;
    setFormData((prevState) => ({
      ...prevState,
      specs: {
        ...prevState.specs,
        facilities: {
          ...prevState.specs.facilities,
          [name]: checked,
        },
      },
    }));
  };

  function handlePicturesChange(event) {
    const files = event.target.files;
    const imagesArray = [];

    // Recursive function to process files sequentially
    const processFile = (index) => {
      if (index >= files.length) {
        // All files processed, update state
        setPics(imagesArray);
        setFormData({
          ...formData,
          specs: {
            ...formData.specs,
            // picture: imagesArray,
          },
        });
        return;
      }

      const fileReader = new FileReader();
      fileReader.onload = () => {
        const base64Image = fileReader.result; // Base64-encoded image
        imagesArray.push(base64Image);
        processFile(index + 1); // Process next file recursively
      };
      // Read the current file as a data URL (base64)
      fileReader.readAsDataURL(files[index]);
    };
    // Start processing files sequentially
    processFile(0);
  }
  const handleRemoveImage = (indexToRemove) => {
    const updatedImages = pics.filter((_, index) => index !== indexToRemove);
    setFormData({
      ...formData,
      specs: {
        ...formData.specs,
        picture: updatedImages,
      },
    });
  };
  const handleVideosChange = (event) => {};

  return (
    <>
      <h2>Complete the following form to continue</h2>
      <AddProperty
        handleInputChange={handleInputChange}
        handleSubmit={handleSubmit}
        handleBuildingInputChange={handleBuildingInputChange}
        handleFacilitiesChange={handleFacilitiesChange}
        handlePicturesChange={handlePicturesChange}
        handleVideosChange={handleVideosChange}
        handleRemoveImage={handleRemoveImage}
        formData={formData}
        errorMessage={errorMessage}
        image={pics}
      />
    </>
  );
};

export default PropertyCrud;
