import React, { useState } from "react";
import SpecialDot from "../specialDot/specialDot";
import "./footer.css";

export default function Footer() {
  const getThisYear = new Date().getFullYear();
  return (
    <>
      <div className="footer">
        <div className="footer-columns">
          <div className="footer-column">
            <h2>Support</h2>
            <p>Help Center</p>
            <p>Rentcover</p>
            <p>Anti-Discrimination</p>
            <p>Daily Support</p>
            <p>Cancellation Options</p>
          </div>
          <div className="footer-column">
            <h2>Managing</h2>
            <p>Manage your home</p>
            <p>Rentcover for owners</p>
            <p>Managing resources</p>
            <p>Community forums</p>
          </div>
          <div className="footer-column">
            <h2>RentPal</h2>
            <p>About us</p>
            <p>Contact us</p>
            <p>Jobs & Careers</p>
            <p>News Center</p>
            <p>Investors</p>
          </div>
        </div>
        <div className="dash"></div>
        <div className="footer-columns lower">
          <div className="footer-column lower-row-first">
            <span>&#169; {getThisYear} RentPal, inc.</span>
            <SpecialDot />
            <span>Terms</span> <SpecialDot />
            <span>Sitemap</span> <SpecialDot />
            <span>Privacy</span>
            <SpecialDot />
            <span>Your Privacy Choices</span>
          </div>
          <div className="footer-column icon-container ">
            <svg
              viewBox="0 0 1024 1024"
              fill="currentColor"
              height="2.4em"
              width="2.4em"
            >
              <path d="M880 112H144c-17.7 0-32 14.3-32 32v736c0 17.7 14.3 32 32 32h736c17.7 0 32-14.3 32-32V144c0-17.7-14.3-32-32-32zm-32 736H663.9V602.2h104l15.6-120.7H663.9v-77.1c0-35 9.7-58.8 59.8-58.8h63.9v-108c-11.1-1.5-49-4.8-93.2-4.8-92.2 0-155.3 56.3-155.3 159.6v89H434.9v120.7h104.3V848H176V176h672v672z" />
            </svg>
            <svg
              data-name="Layer 1"
              viewBox="0 0 24 24"
              fill="currentColor"
              height="2.4em"
              width="2.4em"
            >
              <path d="M21.99 6.547a10.59 10.59 0 00-.103-1.282 4.312 4.312 0 00-.363-1.09A3.853 3.853 0 0019.83 2.48a4.299 4.299 0 00-1.083-.362 10.523 10.523 0 00-1.292-.105c-.183-.007-.42-.01-.53-.01L7.077 2c-.11 0-.347.003-.53.01a10.565 10.565 0 00-1.282.103 4.312 4.312 0 00-1.09.363A3.854 3.854 0 002.48 4.17a4.303 4.303 0 00-.362 1.083 10.545 10.545 0 00-.106 1.292c-.006.183-.01.42-.01.53L2 16.923c0 .11.003.347.01.53a10.565 10.565 0 00.103 1.282 4.313 4.313 0 00.363 1.09A3.854 3.854 0 004.17 21.52a4.305 4.305 0 001.083.362 10.52 10.52 0 001.292.105c.183.007.42.01.53.01l9.848.002c.11 0 .347-.003.53-.01a10.578 10.578 0 001.282-.103 4.316 4.316 0 001.09-.363 3.854 3.854 0 001.696-1.694 4.301 4.301 0 00.362-1.083 10.533 10.533 0 00.106-1.292c.006-.183.01-.42.01-.53L22 7.077c0-.11-.003-.347-.01-.53zm-9.773 12.41h-.003a7.126 7.126 0 01-3.407-.868l-3.78.991 1.012-3.693a7.13 7.13 0 116.178 3.57z" />
              <path d="M12.22 5.901a5.927 5.927 0 00-5.023 9.076l.141.224-.599 2.186 2.243-.588.216.128a5.918 5.918 0 003.016.826h.003A5.926 5.926 0 0012.219 5.9zm3.484 8.47a1.834 1.834 0 01-1.202.847 2.443 2.443 0 01-1.122-.07 10.276 10.276 0 01-1.015-.376 7.94 7.94 0 01-3.043-2.689 3.463 3.463 0 01-.728-1.842 1.997 1.997 0 01.624-1.485.655.655 0 01.475-.223c.118 0 .237 0 .341.006.11.005.256-.042.4.306.15.356.506 1.233.55 1.322a.328.328 0 01.015.312 1.216 1.216 0 01-.178.297c-.09.104-.187.232-.267.312-.09.089-.182.185-.079.363a5.366 5.366 0 00.991 1.234 4.863 4.863 0 001.433.884c.178.09.282.074.386-.045s.445-.52.564-.698.237-.148.4-.089 1.04.49 1.218.58.297.133.341.207a1.488 1.488 0 01-.104.847z" />
            </svg>
            <svg
              fill="currentColor"
              viewBox="0 0 16 16"
              height="2.2em"
              width="2.2em"
            >
              <path d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.917 3.917 0 00-1.417.923A3.927 3.927 0 00.42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.916 3.916 0 001.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.926 3.926 0 00-.923-1.417A3.911 3.911 0 0013.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0h.003zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599.28.28.453.546.598.92.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.47 2.47 0 01-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.478 2.478 0 01-.92-.598 2.48 2.48 0 01-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233 0-2.136.008-2.388.046-3.231.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92.28-.28.546-.453.92-.598.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045v.002zm4.988 1.328a.96.96 0 100 1.92.96.96 0 000-1.92zm-4.27 1.122a4.109 4.109 0 100 8.217 4.109 4.109 0 000-8.217zm0 1.441a2.667 2.667 0 110 5.334 2.667 2.667 0 010-5.334z" />
            </svg>
            <svg
              viewBox="0 0 1024 1024"
              fill="currentColor"
              height="2.4em"
              width="2.4em"
            >
              <path d="M880 112H144c-17.7 0-32 14.3-32 32v736c0 17.7 14.3 32 32 32h736c17.7 0 32-14.3 32-32V144c0-17.7-14.3-32-32-32zM727.3 401.7c.3 4.7.3 9.6.3 14.4 0 146.8-111.8 315.9-316.1 315.9-63 0-121.4-18.3-170.6-49.8 9 1 17.6 1.4 26.8 1.4 52 0 99.8-17.6 137.9-47.4-48.8-1-89.8-33-103.8-77 17.1 2.5 32.5 2.5 50.1-2a111 111 0 01-88.9-109v-1.4c14.7 8.3 32 13.4 50.1 14.1a111.13 111.13 0 01-49.5-92.4c0-20.7 5.4-39.6 15.1-56a315.28 315.28 0 00229 116.1C492 353.1 548.4 292 616.2 292c32 0 60.8 13.4 81.1 35 25.1-4.7 49.1-14.1 70.5-26.7-8.3 25.7-25.7 47.4-48.8 61.1 22.4-2.4 44-8.6 64-17.3-15.1 22.2-34 41.9-55.7 57.6z" />
            </svg>
          </div>
        </div>
      </div>
    </>
  );
}
