import React from "react";
import "./serviceAnimal.css";
import serviceAnimalImage from "../../assets/images/allow-pets.jpg";

const ServiceAnimal = ({ onClose }) => {
  return (
    <div className="service-animals-main-container">
      <div className="service-animals-container">
        <div className="close-btn-container">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="22"
            height="22"
            viewBox="0 0 22 22"
            fill="none"
            onClick={onClose}
            className="close-button"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M20.9447 1.35149C21.2539 1.04232 21.2539 0.541052 20.9447 0.23188C20.6356 -0.0772932 20.1343 -0.0772932 19.8251 0.23188L10.6048 9.45222L1.38449 0.231946C1.07532 -0.0772264 0.574049 -0.0772267 0.264877 0.231946C-0.0442962 0.541119 -0.0442958 1.04239 0.264877 1.35156L9.48516 10.5718L0.231879 19.8251C-0.077293 20.1343 -0.0772933 20.6356 0.231879 20.9447C0.541052 21.2539 1.04232 21.2539 1.35149 20.9447L10.6048 11.6915L19.8581 20.9448C20.1673 21.254 20.6686 21.254 20.9777 20.9448C21.2869 20.6356 21.2869 20.1344 20.9777 19.8252L11.7244 10.5718L20.9447 1.35149Z"
              fill="black"
            />
          </svg>
        </div>
        <img
          src={serviceAnimalImage}
          alt="Service animal with handler"
          className="service-animals-image"
        />
        <div className="service-animals-content">
          <h2>Service animals</h2>
          <p>
            Service animals aren't pets, so there's no need to add them here.
          </p>
          <p>
            Traveling with an emotional support animal? Check out our<br></br>
            <a href="/accessibility-policy">accessibility policy</a>.
          </p>
        </div>
      </div>
    </div>
  );
};

export default ServiceAnimal;
