import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";
import priceRange from "../../staticData/priceRange";
import { dataStats } from "../../staticData/priceRange";
import "./priceSlider.css";

const PriceSlider = () => {
  const [value1, setValue1] = React.useState([20, 37]);
  const [rangeMin, setRangeMin] = useState();
  const [rangeMax, setRangeMax] = useState();

  function valuetext(value) {
    return `${value}°C`;
  }

  const minDistance = 10;

  console.log(value1);
  const handleChange1 = (event, newValue, activeThumb) => {
    if (!Array.isArray(newValue)) {
      return;
    }

    if (activeThumb === 0) {
      setValue1([Math.min(newValue[0], value1[1] - minDistance), value1[1]]);
    } else {
      setValue1([value1[0], Math.max(newValue[1], value1[0] + minDistance)]);
    }
  };
  return (
    <div>
      {dataStats.minValue},{dataStats.avgValue},{dataStats.maxValue}
      <div className="price-slider-container">
        <div className="histogram">
          {priceRange.map((item, index) => (
            <div
              key={index}
              className="bar"
              style={{
                height: `${(item.price * 100) / dataStats.maxValue}px`,
              }}
            ></div>
          ))}
        </div>
      </div>
      <Box sx={{ width: 300 }}>
        <Slider
          getAriaLabel={() => "Minimum distance"}
          value={value1}
          onChange={handleChange1}
          valueLabelDisplay="auto"
          getAriaValueText={valuetext}
          disableSwap
        />
      </Box>
      <div className="price-values">
        <div className="price-box">
          <label htmlFor="minPrice">Minimum</label>
          <input id="minPriceValue" />
        </div>
        <span>—</span>
        <div className="price-box">
          <label htmlFor="maxPrice">Maximum</label>
          <input id="maxPriceValue" />
        </div>
      </div>
    </div>
  );
};

export default PriceSlider;
