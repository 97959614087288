import React from "react";
import styles from "./meterReadings.module.css";

const UserPanel = ({ userData }) => {
  if (!userData) return <p>No data available</p>;

  return (
    <div className={styles.userPanel}>
      <h2>User Data</h2>
      <p>
        Wattage:{" "}
        <span>
          {" "}
          {userData.currentPowerKilowatts !== "-"
            ? parseFloat(userData.currentPowerKilowatts).toFixed(2)
            : "N/A"}
        </span>
      </p>
      <p>
        Units:{" "}
        <span>
          {userData.totalConsumedEnergy !== "-"
            ? parseFloat(userData.totalConsumedEnergy).toFixed(2)
            : "N/A"}
        </span>
      </p>
      <p>
        Allocated Units:{" "}
        <span>{parseFloat(userData.allocatedUnits).toFixed(2) || "N/A"}</span>
      </p>
      <p>
        Purchased Units:{" "}
        <span>{parseFloat(userData.purchasedUnits).toFixed(2) || "N/A"}</span>
      </p>
      <p>
        Relay State:{" "}
        {userData.relayState !== undefined ? (
          userData.relayState ? (
            <span className={styles.relayStateOn}>ON</span>
          ) : (
            <span className={styles.relayStateOff}>OFF</span>
          )
        ) : (
          "N/A"
        )}
      </p>
    </div>
  );
};

export default UserPanel;
