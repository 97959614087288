import { ResidentialSVG } from "../../svgs/rentpal";

export const propertyTypes = [
  {
    icon: <ResidentialSVG />,
    label: "Residential",
  },
  {
    icon: <ResidentialSVG />,
    label: "Residential",
  },
  {
    icon: <ResidentialSVG />,
    label: "Residential",
  },
];
